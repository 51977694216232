<!-- /components/DefaultHeader.vue -->
<template>
    <UHeader :links="links">
        <template #logo>
            <p class="tracking-tight">NomadRx</p>
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <UButton v-if="isAuthenticated" label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle"
                    @click="signOutUser" block variant="soft" />
                <UButton v-else label="Create account" to="/onboard" block variant="solid" size="xl" />
                <UButton v-if="!isAuthenticated" label="Sign in" to="/login" block variant="soft" size="xl" />
            </div>
        </template>
        <template #right>
            <UButton v-if="secondaryButton.show" :label="secondaryButton.label" :color="secondaryButton.color"
                :to="secondaryButton.to" :variant="secondaryButton.variant" />
            <UButton :label="primaryButton.label" :color="primaryButton.color" :to="primaryButton.to"
                :variant="primaryButton.variant" />
        </template>
    </UHeader>
</template>
<script setup lang="ts">
// Use the modular store approach
const identityStore = useIdentityStore();
const accessStore = useAccessStore();

// Destructure refs from stores
const { isAuthenticated, isInitializing } = storeToRefs(identityStore);
const { isAdmin, isClient, isPartner } = storeToRefs(accessStore);

// Firebase auth composable for signout functionality
const { signOutUser } = useFirebaseAuth();
const { isMobile } = useDevice();

const route = useRoute();
console.log('route:', route);

// Watch auth state changes
watch(isAuthenticated, async (newValue) => {
    console.log(`Authentication state changed: ${newValue}`);
});


const primaryButton = computed(() => {
    let buttonOptions = {
        label: 'Sign up',
        color: 'primary',
        to: '/auth/signup',
        variant: 'solid',
    };

    if (!isInitializing.value) {
        if (isClient.value) {
            buttonOptions.label = 'Account';
            buttonOptions.to = '/home';
        } else if (isPartner.value) {
            buttonOptions.label = 'Account';
            buttonOptions.to = '/scribe';
        }
    }

    return buttonOptions;
});

const secondaryButton = computed(() => {
    let buttonOptions = {
        label: 'Sign in',
        color: 'gray',
        to: '/auth/login',
        variant: 'solid',
        show: true,
    };

    if (!isInitializing.value) {
        if (isClient.value || isPartner.value || isMobile) {
            buttonOptions.show = false;
        }
    }

    return buttonOptions;
});

const accountItems = [
    [{
        label: 'Go to homepage',
        icon: 'i-heroicons-home',
        to: "/",
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
];

const links = [{
    label: 'For Patients',
    to: '/for-patients',
    description: 'Find local doctors who take global insurance'
}, {
    label: 'For Providers',
    to: '/for-providers',
    description: 'Provide a great patient experience, get paid quickly, and accept international insurance'
}];
</script>